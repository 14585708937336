<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-overlay
          :show="load"
          variant="transparent"
        >
          <template #overlay>
            <div class="text-center">
              <feather-icon
                icon="ClockIcon"
                size="40"
                animation="cyclon"
              />
              <p>Aguarde...</p>
            </div>
          </template>
          <b-card>
            <b-row>
              <b-col>
                <b-col
                  cols="12"
                >
                  <b-row align-h="between">
                    <b-col
                      md="1"
                      class="my-1"
                    >
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="[10, 15, 20]"
                      />
                    </b-col>
                    <b-col
                      md="2"
                      class="mb-1"
                    >
                      <label>Filtro por Status</label>
                      <v-select
                        v-model="statusFilter"
                        :options="optionsStatus"
                        multiple
                      />
                    </b-col>
                    <b-col
                      md="8"
                      class="my-1"
                    >
                      <b-form-input
                        v-model="filter"
                        placeholder="Pesquisar"
                        type="search"
                        size="sm"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-col>
              <b-col md="12">
                <b-Table
                  hover
                  small
                  striped
                  responsive
                  :per-page="perPage"
                  :current-page="currentPage"
                  :filter="filter"
                  :items="newListFiltered"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="mb-0"
                  @filtered="onFiltered"
                  @row-clicked="rowClick"
                >
                  <template #cell(primeira_execucao)="data">
                    <b-badge :variant="data.item.primeira_execucao === 'ATIVA' ? 'success' : 'danger'">
                      {{ data.item.primeira_execucao }}
                    </b-badge>
                  </template>
                </b-Table>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="center"
                  size="sm"
                  class="my-0"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {
  BRow,
  BCol,
  BCard,
  BTable,
  BPagination,
  BFormInput,
  BFormSelect,
  BOverlay,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BPagination,
    BFormInput,
    BFormSelect,
    BOverlay,
    BBadge,
    vSelect,
  },
  data() {
    return {
      load: true,
      filter: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      fields: [
        { key: 'id', label: 'ID', class: 'text-center' },
        { key: 'nome', label: 'Nome', class: 'text-center' },
        { key: 'tipo', label: 'Tipo', class: 'text-center' },
        { key: 'respiro', label: 'Respiro', class: 'text-center' },
        { key: 'status', label: 'STATUS', class: 'text-center' },
      ],
      reguas: [],
      statusFilter: [],
    }
  },
  computed: {
    optionsStatus() {
      const { reguas } = this
      const status = []

      reguas.map(item => {
        item.status ? status.push(item.status) : null
      })

      const itemsFiltered = [...new Set(status)]

      return itemsFiltered
    },
    newListFiltered() {
      const { reguas, statusFilter: sF } = this
      let itens = reguas

      if (sF.length > 0) {
        itens = reguas.filter(item => (
          (sF.length === 0 || sF.includes(item.status))
        ))
      }
      this.totalRows = itens.length
      return itens
    },
  },
  async created() {
    await this.getReguas()
  },
  methods: {
    rowClick(item) {
      this.$router.push({ name: 'regua.view', params: { id: item.id } })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getReguas() {
      this.reguas = []
      this.load = true
      axios.get('api/v1/regua_cobranca/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          Accept: 'application/json',
        },
      })
        .then(res => {
          res.data.dados.map(dt => {
            this.reguas.push({
              id: dt.id,
              tipo: dt.tipo,
              nome: dt.nome,
              respiro: dt.respiro,
              status: dt.status,
            })
          })
          this.totalRows = this.reguas.length
          this.load = false
        })
    },
  },
}
</script>
