<template>
  <div>
    <b-row align-h="end">
      <b-col cols="auto">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modalRegua
          class="mb-1"
          variant="success"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Regua</span>
        </b-button>
      </b-col>
    </b-row>
    <b-button
      v-show="closebt"
      id="fechar-modal"
      @click="hideModal"
    >
      X
    </b-button>
    <regua-list ref="table" />
    <b-modal
      id="modalRegua"
      ref="modalRegua"
      title="Régua de Cobrança"
      size="lg"
      hide-footer
    >
      <form-regua :hide="hideModal" />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BModal,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import ReguaList from './ReguaList.vue'
import FormRegua from './cards/FormRegua.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    ReguaList,
    BModal,
    FormRegua,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      closebt: false,
    }
  },
  methods: {
    async updateTable() {
      this.$refs.table.getReguas()
    },
    hideModal() {
      this.updateTable()
      this.$bvModal.hide
      this.$nextTick(() => {
        this.$refs.modalRegua.toggle('#toggle-btn')
      })
    },
    loading(state = true) {
      this.$refs.refresh.showLoading = state
    },
  },
}
</script>
