<template>
  <div>
    <b-container>
      <b-row
        align-h="around"
      >
        <b-col>
          <transition-group
            name="leave"
            class="d-flex flex-wrap justify-content-between align-items-center mt-1"
          >
            <b-card
              v-for="(item,index) in itemsAcao"
              :key="index"
              class="ml-1"
              :title="`Ação: ${item.tipo_campanha}`"
            >
              <b-card-text>
                <span><strong>Inicio:</strong></span> {{ reverseDateString(item.data_inicio_campanha) }}
              </b-card-text>
              <b-card-text>
                <span><strong>Final:</strong></span> {{ reverseDateString(item.data_final_campanha) }}
              </b-card-text>
              <div>
                <feather-icon
                  icon="XIcon"
                  size="18"
                  class="ml-2 cursor-pointer color-icon iconeX"
                  @click="removeAcao(index)"
                />
                <feather-icon
                  icon="CopyIcon"
                  size="18"
                  class="mr-1 ml-2 cursor-pointer"
                  @click="duplicateAcao(index)"
                />
                <feather-icon
                  icon="EditIcon"
                  size="18"
                  class="ml-2 cursor-pointer color-icon iconeEdit"
                  @click="editAcao(index)"
                />
              </div>
            </b-card>
          </transition-group>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      ref="modalEditarAcao"
      title="Editar data da ação"
      @ok="salvarAcaoEditada"
    >
      <b-row>
        <b-col cols="4">
          <label>Data de Início</label>
          <b-form-input
            id="data-inicio"
            v-model="dataAcaoEditada"
            size="ml"
            locale="pt"
            type="date"
          />
        </b-col>
        <b-col md="4">
          <div>
            <label for="hora-inicio">Horário Inícial</label>
            <b-form-input
              id="hora-inicio"
              v-model="horaAcaoEditada"
              type="time"
              size="sm"
              placeholder="Inicio"
              local="en"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <label>Data Final</label>
          <b-form-input
            id="data-inicio"
            v-model="dataAcaoFinalEditada"
            size="ml"
            locale="pt"
            type="date"
          />
        </b-col>
        <b-col md="4">
          <div>
            <label for="hora-inicio">Horário Final</label>
            <b-form-input
              id="hora-inicio"
              v-model="horaAcaoFinalEditada"
              type="time"
              size="sm"
              placeholder="Inicio"
              local="en"
            />
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BContainer, BCard, BRow, BCol, BCardText, BModal, BFormInput } from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BCard,
    BRow,
    BCol,
    BCardText,
    BModal,
    BFormInput,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      itemsAcao: [],
      dataAcaoEditada: '',
      indiceAcaoEditada: null,
      horaAcaoEditada: '',
      dataAcaoFinalEditada: '',
      horaAcaoFinalEditada: '',
    }
  },
  mounted() {
    this.getAcoesList()
  },
  methods: {
    getAcoesList() {
      this.itemsAcao = this.items
    },
    reverseDateString(date) {
      const dateToReverse = date.substr(0, 10)
      const dateHours = date.substr(11, 15)
      const reversedDate = dateToReverse.split('-').reverse().join('/')
      return `${reversedDate} ${dateHours}`
    },
    removeAcao(index) {
      this.$swal({
        title: '',
        text: 'Deseja remover essa ação?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.itemsAcao.splice(index, 1)
          localStorage.setItem('campanhasAcoes', JSON.stringify(this.itemsAcao))
        }
      })
    },
    duplicateAcao(index) {
      const newAcao = { ...this.itemsAcao[index] }
      this.itemsAcao.splice(index + 1, 0, newAcao)
      localStorage.setItem('campanhasAcoes', JSON.stringify(this.itemsAcao))
    },
    editAcao(index) {
      const acao = this.itemsAcao[index]
      this.dataAcaoEditada = acao.data_inicio_campanha.substr(0, 10)
      this.horaAcaoEditada = acao.data_inicio_campanha.substr(11, 5)
      this.dataAcaoFinalEditada = acao.data_final_campanha.substr(0, 10)
      this.horaAcaoFinalEditada = acao.data_final_campanha.substr(11, 5)
      this.indiceAcaoEditada = index
      this.$refs.modalEditarAcao.show()
    },
    salvarAcaoEditada() {
      const acao = this.itemsAcao[this.indiceAcaoEditada]
      const novaData = `${this.dataAcaoEditada} ${this.horaAcaoEditada}`
      const novaDataFinal = `${this.dataAcaoFinalEditada} ${this.horaAcaoFinalEditada}`
      const novaAcao = { ...acao, data_inicio_campanha: novaData, data_final_campanha: novaDataFinal }
      this.itemsAcao.splice(this.indiceAcaoEditada, 1, novaAcao)
      localStorage.setItem('campanhasAcoes', JSON.stringify(this.itemsAcao))
    },

  },
}
</script>

<style>
.iconeX {
  color: red;
}
.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.leave-enter-active, .leave-leave-active {
  transform-origin: 50% 50%;
  transition: transform .5s cubic-bezier(0.61, 1, 0.88, 1);
  transition-property: transform, opacity;
}
.leave-enter, .leave-leave-to {
  transform: scale(0) rotate(-60deg);
}
</style>
